import React, { FC } from 'react'
import { BlockText } from '../../../../core/models/BlockText'
import styles from './advantages.module.scss'
import SolveProblemsIcon from '../../../../assets/components-icons/advantages/solveProblems.svg'
import YouCanPickUpIcon from '../../../../assets/components-icons/advantages/youCanPickUp.svg'
import ResultsIcon from '../../../../assets/components-icons/advantages/results.svg'
import FreeListeningIcon from '../../../../assets/components-icons/advantages/freeListening.svg'

interface Props {
  localization: AdvantagesLocalization
}

export const Advantages: FC<Props> = props => {
  const { localization } = props
  return (
    <div className={styles.advantages}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.wrapperTitle}>
          <h2 className={`semi-title`}>{localization.title}</h2>
          <p className={`sub-info ${styles.subInfo}`}>{localization.text}</p>
        </div>

        <div className={styles.advantagesCards}>
          <div className={styles.advantagesCard}>
            <div className={styles.advantagesIcon}>
              <SolveProblemsIcon viewBox="0 0 80 70" className={styles.pathSvg} />
            </div>
            <div className={styles.advantagesCardContent}>
              <div className={styles.advantagesCardName}>{localization.solveProblems.title}</div>
              <div className={styles.advantagesCardInfo}>{localization.solveProblems.text}</div>
            </div>
          </div>

          <div className={styles.advantagesCard}>
            <div className={styles.advantagesIcon}>
              <YouCanPickUpIcon viewBox="0 0 80 70" className={styles.pathSvg} />
            </div>
            <div className={styles.advantagesCardContent}>
              <div className={styles.advantagesCardName}>{localization.results.title}</div>
              <div className={styles.advantagesCardInfo}>{localization.results.text}</div>
            </div>
          </div>

          <div className={styles.advantagesCard}>
            <div className={styles.advantagesIcon}>
              <ResultsIcon viewBox="0 0 80 70" className={styles.pathSvg} />
            </div>
            <div className={styles.advantagesCardContent}>
              <div className={styles.advantagesCardName}>{localization.youCanPickUp.title}</div>
              <div className={styles.advantagesCardInfo}>{localization.youCanPickUp.text}</div>
            </div>
          </div>

          <div className={styles.advantagesCard}>
            <div className={styles.advantagesIcon}>
              <FreeListeningIcon viewBox="0 0 80 70" className={styles.pathSvg} />
            </div>
            <div className={styles.advantagesCardContent}>
              <div className={styles.advantagesCardName}>{localization.freeListening.title}</div>
              <div className={styles.advantagesCardInfo}>{localization.freeListening.text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface AdvantagesLocalization extends BlockText {
  solveProblems: BlockText
  youCanPickUp: BlockText
  results: BlockText
  freeListening: BlockText
}
