import React, { useCallback } from 'react'
import { GetStaticProps } from 'next'
import { Layout } from '../components/Layout'
import { Dashboard, DashboardLocalization } from '../components/Dashboard/Dashboard'
import { dataProvider } from '../static/dataProvider'
import { useClassifficationsData } from '../hooks/data/useClassifficationsData'
import { usePopularServicesData } from '../hooks/data/usePopularServicesData'
import { useRouter } from 'next/router'
import { PathPrefix } from '../static/enums/PathPrefix'
import { PageName } from '../static/enums/PageName'
import { ClassificationsQuery, MedicalService, SearchType } from '../graphql/generated/types.generated'
import { Path } from '../static/enums/Path'
import { SearchParam } from '../static/enums/SearchParam'

interface Props {
  localization: DashboardLocalization
  popularServices: MedicalService[]
  classifications: ClassificationsQuery[]
}

export default function (props: Props) {
  const { push } = useRouter()

  const { localization, popularServices, classifications } = useStaticData(props)

  const openOctagonPage = useCallback(
    (classificationId: number) => {
      push(`/${PageName.OCTAGON}/${PathPrefix.CATEGORY}/${classificationId}`)
    },
    [push]
  )

  const openSearchPage = useCallback(
    (val: string) => {
      const path = `${Path.SEARCH}${PathPrefix.CATEGORY}?${SearchParam.TEXT}=${val},${SearchParam.SEARCH_TYPE}=${SearchType.All}`
      push(path)
    },
    [push]
  )

  const seAllServices = useCallback(() => {}, [])

  return (
    <Layout statesFooter signIn logoLink>
      <Dashboard
        popularServices={popularServices}
        localization={localization}
        classifications={classifications}
        openOctagonPage={openOctagonPage}
        openSearchPage={openSearchPage}
        seAllServices={seAllServices}
      />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const localization: DashboardLocalization = {
    fields: {
      or: 'or',
      canWeHelp: 'Hello, can we help you find the treatment or health service?',
    },
    searchInput: {
      placeholder: 'You can search some services, clinics, doctors, technologies or products here',
      buttonText: 'Search',
    },
    octagon: {
      fields: {
        personalizeSearch: 'Personalize your search',
        chooseLocation: 'Choose your location',
        symptomChecker: 'Symptom checker',
      },
    },
    advantages: {
      title: 'Advantages',
      text: 'Knowledge gives you power to decide for your own health. Power to decide gives you freedom and happiness. MapViser - a roadmap to personal treatment decisions and healthy life.',
      solveProblems: {
        title: 'Treatments explained',
        text: 'Each disease can be treated in many way.  Ho do you know  what is right for you? Do you know what else you can try? We offer the options, present the scientific information about them, and show the places where these procedures can be done.',
      },
      youCanPickUp: {
        title: 'Choices presented',
        text: "Find all unbiased information that will empower you to make the best choice. Treatment and products are rated by method, modern equipment, provider qualification and facility. Here all information is connected on a single card - click'n chose!",
      },
      results: {
        title: 'Procedures compared',
        text: 'Procedures and treatment methods are explained and compared in safety, results and prices. Medical information and complex diagnoses are made simple for understanding. We discuss medical technology which is crucial for procedure results.',
      },
      freeListening: {
        title: 'Experts presented',
        text: 'Professionals are rated only by their scientific merits and skills - no paid ads. We find experts who have special skills or knowledge in a special procedure or a disease - clinicians and scientists acknowledged by colleagues and patients.',
      },
    },
    popular: {
      title: 'Popular services',
      text: 'Find out which procedures are preferred by our users and recommended by our professionals',
      buttonText: 'See all',
    },
    about: {
      title: 'About MapViser',
      text: 'What is different about MV?',
      allInfo: `MV came into existence as a response to an overwhelming questions from our patients about their health. A group of physicians and scientist got together in a big quest to bring together a  all the medical resources scattered in different locations and hidden behind the doors of the scientific laboratories. We realized how difficult it is for a patient to manage their own health - everyone is unique and has personal believes. Everyone has a different budget and family needs. Healthcare is complex and poorly explained, specialties are disconnected and even doctors have limited knowledge about other specialists.
MapViser is the first step in the future of personal medicine. We start from simple dot-to-dot connections between the diagnosis and treatment, between a conventional and natural medicine, and between the medical scientists and outstanding clinicians. We aim to gather resources without borders, so the knowledge of one University or one hospital can help each and everyone without borders.
We thought of new ways to help disadvantaged people to navigate medicine - we created visual search Octagon and working on AI that will be able to navigate a person with restrains though the best available and individually fit resources.
For all of us MapViser is a platform where we can share our knowledge and can bring a hope of healthy life into every distant home. Maybe we are all dreamers, but we hope to help millions of people to find the best chance to live a healthy life.`,
    },
  }

  // const { getClassiffications, getPopularServices } = dataProvider

  // const [classifications, popularServices] = await Promise.all([getClassiffications(), getPopularServices()])

  return {
    props: {
      localization,
      popularServices: [],
      classifications: [],
    },
  }
}

const useStaticData = (props: Props) => {
  const classifications = useClassifficationsData(props.classifications)
  const [popularServices] = usePopularServicesData(props.popularServices)

  return {
    classifications,
    popularServices,
    localization: props.localization,
  }
}
