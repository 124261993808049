import { useState } from 'react'
import { DataUtil } from '../../core/utils/DataUtil'
import { MedicalService } from '../../graphql/generated/types.generated'

const { getRidOfNull } = DataUtil

export const usePopularServicesData = (popularServices: MedicalService[]) => {
  return useState(() =>
    popularServices.slice(0, 4).map(a => {
      //TODO:
      const item: any = {
        id: a.id,
        name: a.name,
        note: getRidOfNull(a.note),
        image: getRidOfNull(a.image),
        tags: [],
        specialties: [],
      }
      return item
    })
  )
}
