import React, { FC } from 'react'
import styles from './comingSoon.module.scss'
import { Slider } from '../../../Slider/Slider'
import { Button, ButtonsColors, ButtonsSizes } from '../../../../core/components/Button'
import { Path } from '../../../../static/enums/Path'
import { useRouter } from 'next/router'

interface Props {
  localization?: ComingSoonLocalization
}

export const ComingSoon: FC<Props> = props => {
  const { push } = useRouter()

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    mobileFirst: true,
    // autoplay: true,
    // autoplaySpeed: 8000,
  }

  return (
    <div className={styles.comingSoon}>
      <Slider
        settings={settings}
        className={`slick-arrows-white coming-soon-arrows slick-dots-white coming-soon-dots ${styles.sliderComingSoon}`}
      >
        <div className={`${styles.item} ${styles.first}`}>
          <div className="container">
            <div className={styles.comingSoonContent}>
              <div className={styles.comingSoonTitle}>
                NEW HELP IN FINDING BEST TREATMENT POSSIBILITIES - COMING SOON!
              </div>

              <div className={styles.comingSoonText}>
                If you have a diagnosis and want to know your treatment options – this is the place. Find out who can
                perform the procedure or service you need – conventional, natural or experimental.
              </div>

              <div>
                <Button
                  onClick={() => push('/diseases')}
                  color={ButtonsColors.ORANGE}
                  size={ButtonsSizes.LARGE}
                  text={'Check our diseases'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.item} ${styles.second}`}>
          <div className="container">
            <div className={styles.comingSoonContent}>
              <div className={styles.comingSoonTitle}>
                COMPARE MEDICAL SERVICE OFFERED BY BEST SPECIALISTS - COMING SOON!
              </div>

              <div className={styles.comingSoonText}>
                Learn about medical treatments, compare specialists, clinics and Expert options. You have the right to
                choose what you want.
              </div>

              <div>
                <Button
                  onClick={() => push('/diseases')}
                  color={ButtonsColors.ORANGE}
                  size={ButtonsSizes.LARGE}
                  text={'Read as professional'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.item} ${styles.third}`}>
          <div className="container">
            <div className={styles.comingSoonContent}>
              <div className={styles.comingSoonTitle}>WE WANT TO HELP YOU MAKE AN EDUCATED CHOICE!</div>

              <div className={styles.comingSoonText}>
                Best medical experts make understanding of the diseases simple.
              </div>

              <div>
                <Button
                  onClick={() => push('/diseases')}
                  color={ButtonsColors.ORANGE}
                  size={ButtonsSizes.LARGE}
                  text={'Check our diseases'}
                />
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  )
}

interface ComingSoonLocalization {}

const localization = {}
