import React, { FC, useCallback } from 'react'
import { TreeOption } from '../../core/models/TreeOption'
import { Service } from '../../models/Service'
import { AboutMapviser, AboutMapviserLocalization } from './components/AboutMapviser/AboutMapviser'
import { Advantages, AdvantagesLocalization } from './components/Advantages/Advantages'
import { DashboardOctagon, DashboardOctagonLocalization } from './components/DashboardOctagon/DashboardOctagon'
import { PopularServices, PopularServicesLocalization } from './components/PopularServices/PopularServices'
import { SearchInput, SearchInputFormData, SearchInputLocalization } from './components/SearchInput/SearchInput'
import { SubscribePlug } from '../SubscribePlug/SubscribePlug'
import dashboard from './dashboard.module.scss'
import { ComingSoon } from './components/ComingSoon/ComingSoon'
import { ComingSoonPopularServices } from './components/ComingSoonPopularServices/ComingSoonPopularServices'

interface Props {
  localization: DashboardLocalization
  classifications: TreeOption[]
  popularServices: Service[]
  openOctagonPage: (pageId: number) => void
  openSearchPage: (searchText: string) => void
  seAllServices: () => void
}

export const Dashboard: FC<Props> = props => {
  const { localization, classifications, popularServices, openOctagonPage, openSearchPage, seAllServices } = props

  const { fields } = localization

  const onSearchInputSubmit = useCallback(
    (data: SearchInputFormData) => {
      openSearchPage(data.searchText)
    },
    [openSearchPage]
  )

  return (
    <div>
      <ComingSoon />
      {/*TODO: #for_blog*/}
      {/*<div className={dashboard.wrapper}>*/}
      {/*  <div className={`container`}>*/}
      {/*    <h1 className={dashboard.title}>{fields.canWeHelp}</h1>*/}
      {/*    <div className={dashboard.searchWrapper}>*/}
      {/*      <SearchInput onSubmit={onSearchInputSubmit} localization={localization.searchInput} />*/}
      {/*    </div>*/}
      {/*    <div className={dashboard.orWrapper}>*/}
      {/*      <span className={dashboard.orText}>{fields.or}</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className={`container`}>*/}
      {/*    <DashboardOctagon*/}
      {/*      classifications={classifications}*/}
      {/*      localization={localization.octagon}*/}
      {/*      openOctagonPage={openOctagonPage}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className={dashboard.blur}>*/}
      {/*    <span>Coming soon</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Advantages localization={localization.advantages} />
      <ComingSoonPopularServices />
      {/*TODO: #for_blog*/}
      {/*<PopularServices*/}
      {/*  popularServices={popularServices}*/}
      {/*  localization={localization.popular}*/}
      {/*  onSeeAllClick={seAllServices}*/}
      {/*/>*/}
      {/*  <AboutMapviser localization={localization.about} />*/}
      {/*TODO: #for_blog*/}
      {/*<SubscribePlug />*/}
    </div>
  )
}

export interface DashboardLocalization {
  fields: {
    or: string
    canWeHelp: string
  }
  searchInput: SearchInputLocalization
  octagon: DashboardOctagonLocalization
  advantages: AdvantagesLocalization
  about: AboutMapviserLocalization
  popular: PopularServicesLocalization
}
