import { TreeUtil } from '../core/utils/TreeUtil'
import { ClassificationsQuery } from '../graphql/generated/types.generated'

export const dataParser = {
  toCategories: (classifications: ClassificationsQuery[]) => {
    return TreeUtil.createTreeNodes(
      classifications,
      a => a.parentId,
      a => ({
        value: a.id,
        label: a.title ?? 'unknown',
        children: [],
      })
    )
  },
}
