import React, { FC } from 'react'
import styles from './comingSoonPopularServices.module.scss'
import { Button } from '../../../../core/components/Button'
import ByInterestIcon from '../../../../assets/components-icons/services/by-interest.svg'
import SpecialtyIcon from '../../../../assets/components-icons/services/specialty.svg'
import BodyOrgansIcon from '../../../../assets/components-icons/services/body-organs.svg'

interface Props {
  localization?: ComingSoonPopularServicesLocalization
}

export const ComingSoonPopularServices: FC<Props> = props => {
  return (
    <div className={styles.popularServices}>
      <div className={`container`}>
        <div className={styles.wrapper}>
          <div className={styles.wrapperContent}>
            <h2 className={`semi-title semi-title_left ${styles.semiTitle}`}>{localization.title}</h2>
            <p className={`sub-info sub-info_left ${styles.subInfo}`}>{localization.text}</p>
            {/*<Button className={styles.button} text={localization.buttonText} />*/}
          </div>

          <div className={styles.wrapperCards}>
            <div className={styles.wrapperCardsTwo}>
              <div className={`${styles.cardComingSoon} ${styles.verticalCard}`}>
                <div className={`${styles.cardComingSoonIcon} ${styles.verticalCardIcon}`}>
                  <ByInterestIcon viewBox="0 0 77 80" />
                </div>

                <div className={styles.cardName}>{localization.interest}</div>

                {/*<div className={styles.cardChoose}>{localization.choose}</div>*/}
              </div>

              <div className={`${styles.cardComingSoon} ${styles.horizontalCard}`}>
                <div>
                  <div className={styles.cardName}>{localization.specialty}</div>

                  {/*<div className={styles.cardChoose}>{localization.choose}</div>*/}
                </div>

                <div className={`${styles.cardComingSoonIcon} ${styles.horizontalCardIcon}`}>
                  <SpecialtyIcon viewBox="0 0 67 64" />
                </div>
              </div>
            </div>

            <div className={`${styles.cardComingSoon} ${styles.verticalCardLarge}`}>
              <div className={`${styles.cardComingSoonIcon} ${styles.verticalCardLargeIcon}`}>
                <BodyOrgansIcon viewBox="0 0 108 125" />
              </div>

              <div className={styles.cardName}>{localization.bodyOrgans}</div>

              {/*<div className={styles.cardChoose}>{localization.choose}</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface ComingSoonPopularServicesLocalization {
  title: string
  text: string
  buttonText: string
  interest: string
  specialty: string
  bodyOrgans: string
  choose: string
}

const localization = {
  title: 'Popular services',
  text: '',
  buttonText: 'Learn about',
  interest: 'By interest',
  specialty: 'Specialty',
  bodyOrgans: 'Body organs',
  choose: 'choose',
}
