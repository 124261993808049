import { TreeOption } from '../models/TreeOption'
import { ArrayUtil } from './ArrayUtil'

export const TreeUtil = {
  findInTree: function <T extends string | number>(array: TreeOption<T>[], key: T): TreeOption<T> | undefined {
    let result = array.find(a => a.value === key)

    if (result) {
      return result
    }

    for (var subItem of array) {
      result = TreeUtil.findInTree(subItem.children, key)

      if (result) {
        return result
      }
    }
  },
  createTreeNodes: function <TKey extends string | number, TItem>(
    array: TItem[],
    getParentKey: (item: TItem) => TKey | null | undefined,
    createResultItem: (item: TItem) => TreeOption<TKey>
  ) {
    const parsed = array.map(a => {
      //TODO: understand why we have nullable title
      const item = createResultItem(a)
      const parentId = getParentKey(a)

      return { item, parentId }
    })

    const map = ArrayUtil.toMap(parsed, a => a.item.value)
    const result: TreeOption<TKey>[] = []

    for (var [_, value] of map) {
      const { parentId, item } = value

      if (parentId === undefined || parentId === null) {
        result.push(item)
        continue
      }

      const parentTuple = map.get(parentId)

      if (!parentTuple) {
        throw `Cannot find parent with id ${parentId}`
      }

      const { item: parent } = parentTuple

      item.parent = parent
      parent.children.push(item)
    }

    return result
  },
  eachNode: function <T extends string | number>(array: TreeOption<T>[], action: (node: TreeOption<T>) => void) {
    for (var node of array) {
      action(node)
      TreeUtil.eachNode(node.children, action)
    }
  },
  eachParent: function <T extends string | number>(node: TreeOption<T>, action: (node: TreeOption<T>) => void) {
    let curNode: TreeOption<T> | undefined = node
    while (curNode) {
      action(curNode)
      curNode = curNode.parent
    }
  },
  toMap: function <T extends string | number>(array: TreeOption<T>[]) {
    var map = new Map<T, TreeOption<T>>()
    TreeUtil.eachNode(array, node => {
      map.set(node.value, node)
    })
    return map
  },
}
